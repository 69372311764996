import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Img from "gatsby-image"
const slugify = require("slugify")
import NewsletterForm from "../components/newsletter-form"

export default class Blog extends React.Component<any, any> {
  render() {
    const posts = this.props.data.allMdx.nodes

    return (
      <Layout>
        <SEO title="Blog" description="Les derniers articles Supravore." />
        <Header />

        <main className="content blog-list">
          <section className="content__row content__row--overlap">
            <h2 className="content__row-title">Articles</h2>
            <ul className="product-grid product-grid">{posts.map(this.renderCard)}</ul>
            <NewsletterForm />
          </section>
        </main>
      </Layout>
    )
  }

  renderCard = (item, index) => {
    const { title, summary, featuredImage } = item.frontmatter
    const href = "/blog/" + slugify(title.toLowerCase(), { lower: true, strict: true })
    return (
      <li key={index} className="product-grid__item blog-card">
        <Link to={href}>
          <figure className="js-figure-link product-grid__item-figure js-figure-link">
            <picture>
              <Img
                fluid={featuredImage.childImageSharp.fluid}
                alt={title}
                className="product-grid__item-image product-grid__item-image--featured"
              />
            </picture>
          </figure>
        </Link>
        <div className="product-grid__definition">
          <h3 className="product-grid__title">
            <Link to={href}>{title}</Link>
          </h3>
          <span className="product-grid__category">{summary}</span>
        </div>
      </li>
    )
  }
}

export const pageQuery = graphql`
  query BlogPostList {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { published: { eq: true } }, fileAbsolutePath: { regex: "/blog/" } }
    ) {
      nodes {
        frontmatter {
          title
          summary
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
